
import { defineComponent, PropType } from "vue";
import { IAnime } from "@/libs/interfaces/Anime";

export default defineComponent({
  name: "ResultsBox",
  props: {
    results: Array as PropType<Array<IAnime>>
  },
  emits: ["select-anime"],
  setup(props, { emit }) {
    const selectResult = (result: IAnime) => emit("select-anime", result);
    return { selectResult };
  }
});
