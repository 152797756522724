
import { defineComponent, PropType } from "vue";
import Poster from "@/components/Poster.vue";
import { IAnime } from "@/libs/interfaces/Anime";

export default defineComponent({
  name: "AnimeBox",
  components: {
    Poster
  },
  props: {
    anime: Object as PropType<IAnime>
  }
});
