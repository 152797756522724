<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 122.88 80.59"
    style="enable-background:new 0 0 122.88 80.59"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M122.88,27.53L94.25,0v17.72h-22.7v19.62h22.7v17.72L122.88,27.53L122.88,27.53L122.88,27.53z M0,53.06 l28.63,27.53V62.87h22.7V43.25h-22.7V25.53L0,53.06L0,53.06z M54.84,62.87h13.56V43.25H54.84V62.87L54.84,62.87z M71.64,62.87h7.7 V43.25h-7.7V62.87L71.64,62.87z M51.24,17.72h-7.7v19.62h7.7V17.72L51.24,17.72z M68.04,17.72H54.49v19.62h13.56V17.72L68.04,17.72 z"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 1.75rem;
}

.st0 {
  transform-origin: center;
  fill: #ffffff;
  fill-rule: evenodd;
  clip-rule: evenodd;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CompareArrow"
});
</script>
