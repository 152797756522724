
import { defineComponent, PropType } from "vue";
import { ISeiyuuFound } from "@/libs/interfaces/Seiyuu";
import Poster from "@/components/Poster.vue";

export default defineComponent({
  name: "ComparisonBox",
  props: {
    seiyuuList: Object as PropType<Array<ISeiyuuFound>>
  },
  components: { Poster }
});
