
import { defineComponent } from "vue";
import Logo from "@/assets/icons/Logo.vue";

export default defineComponent({
  name: "Poster",
  props: {
    imageLink: String,
    imageAlt: String
  },
  components: { Logo }
});
