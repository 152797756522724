<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 17 16">
    <text transform="translate(0 13)" fill="#572cbe" font-size="14" font-family="Insaniburger with Cheese">
      <tspan x="0" y="0">A</tspan>
      <tspan y="0" fill="#fff">S</tspan>
    </text>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Logo"
});
</script>
