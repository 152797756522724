
import { defineComponent, ref } from "vue";
import ResultsBox from "@/components/ResultsBox.vue";
import { IAnime } from "@/libs/interfaces/Anime";
import Throbber from "@/components/Throbber.vue";
import { animeSearchQuery } from "../libs/queries/AnimeSearch";

export default defineComponent({
  name: "Search",
  components: {
    ResultsBox,
    Throbber
  },
  emits: ["select-anime"],
  setup(props, { emit }) {
    let timeoutRef: null | number = null;

    const loading = ref(false);
    const query = ref("");
    const results = ref([] as Array<IAnime>);
    const show = ref(false);

    const search = async () => {
      if (timeoutRef !== null) {
        clearTimeout(timeoutRef);
      }

      timeoutRef = setTimeout(async () => {
        if (query.value === "") {
          results.value = [];
          return;
        }

        loading.value = true;
        const data = await fetch("https://graphql.anilist.co", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: JSON.stringify({
            query: animeSearchQuery,
            variables: {
              search: query.value
            }
          })
        }).then(res => res.json());
        results.value = data.data.Page.media;
        loading.value = false;
      }, 400);
    };

    const selectAnime = (anime: IAnime) => {
      emit("select-anime", anime);
      query.value = anime.title.english || anime.title.romaji;
    };

    return { loading, query, results, show, search, selectAnime };
  }
});
